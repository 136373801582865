//library imports
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//actions
import { changeClientUserStatus, SlackUsers, changeAdminStatus } from "../../actions/admin";

//components
import BackDrop from "../../components/BackDrop/BackDrop";
import Modal from "../../components/Modal/Modal";
import ToggleButton from "../../components/Utilities/ToggleButton/ToggleButton";
import Loading from "../../components/Utilities/Loading/Loading";
import { useUserTokenSelector } from "store/reducers";
import { startLoading, stopLoading } from "store/actions";

const SlackClientScreen = (props) => {
  const [error, setError] = useState("");
  const [canCancel, setCanCancel] = useState(false);
  const [userId, setUserId] = useState("");
  const userToken = useUserTokenSelector();
  const dispatch = useDispatch();
  const [slackClientTeams, setSlackClientTeams] = useState([]);

  //loads the corporate client name and all its users
  const clientAllUsers = async () => {
    try {
      dispatch(startLoading());
      const clientId = props.match.params.clientId;
      const res = await SlackUsers(clientId, userToken);
      setSlackClientTeams(res.data);

      dispatch(stopLoading());
      setError("");
    } catch (error) {
      dispatch(stopLoading());
      setError(error.response.data.error);
    }
  };

  //called on initial render
  useEffect(() => {
    clientAllUsers();
    // eslint-disable-next-line
  }, []);

  //handles cancelling of modal
  const handleModalCancelHandler = async () => {
    try {
      setCanCancel(false);
    } catch (err) {
      console.log(err);
      setError(err.response.data.error);
    }
  };

  //handles confirmation of client status change in modal
  const handleModalConfirmHandler = async () => {
    try {
      dispatch(startLoading());
      const clientId = props.match.params.clientId;
      //returns a response
      await changeClientUserStatus(clientId, userId, userToken);
      setCanCancel(false);
      await clientAllUsers();
      setUserId("");
      dispatch(stopLoading());
    } catch (err) {
      dispatch(stopLoading());
      console.log(err);
      setError(err.response.data.error);
    }
  };

  //changes status to active
  const handleStatusClickButton = async (e) => {
    try {
    dispatch(startLoading());
    const newUserId = e.target.value;
    setUserId(newUserId);
    await changeAdminStatus(newUserId, userToken);

    await clientAllUsers();
    dispatch(stopLoading());
    }
    catch (error) {
      dispatch(stopLoading());
      setError(error.message);
    }
  };

  return (
      <div>
        {canCancel && (
          <>
            <BackDrop />
            <Modal
              title="Decision"
              onCancel={handleModalCancelHandler}
              onConfirm={handleModalConfirmHandler}
              canCancel={true}
              canConfirm={true}
              confirmText={"Confirm"}
            >
              <p>Are You sure Do you want this ?</p>
            </Modal>
          </>
        )}
        {slackClientTeams.map((team, index) => (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px"
            }}
          >
            {error && <p>{error}</p>}
            <h1>
              <Link to="/dashboard">{team.teamName}</Link>
            </h1>
            <p style={{fontSize: "16px"}}>
              Date Installed: <b>{team.dateInstalled}</b>
            </p>
            <table >
              {team.members.length !== 0 ? (
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Slack-Display Name</th>
                    <th>Make Admin</th>
                  </tr>
                </thead>
              ) : (
                <tbody>
                  <tr>
                    <th> No Users Registered .</th>
                  </tr>
                </tbody>
              )}
              <tbody>
                {team.members &&
                  team.members.map((user) => {
                    const { firstName, email, slackDisplayName, lastName, userID, isAdmin } = user;
                    return (
                      <tr key={userID} >
                        <td>
                          {firstName} {lastName}
                        </td>
                        <td>{email}</td>
                        <td>{slackDisplayName}</td>
                        <td>
                          <ToggleButton
                            value={userID}
                            name={userID}
                            checked={isAdmin}
                            onClick={handleStatusClickButton}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ))}
      </div>
  );
};

export default SlackClientScreen;
