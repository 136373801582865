import { useSelector } from "react-redux";
import {
  REMOVE_AVAILABLE_FEATURES,
  REMOVE_USER_INFO,
  REMOVE_USER_TOKEN,
  SET_AVAILABLE_FEATURES,
  SET_LOADING,
  SET_USER_INFO,
  SET_USER_TOKEN,
} from "./actions";

// auth reducer
const initialAuthState = {
  userToken: "",
};

export const authReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case SET_USER_TOKEN:
      return { ...state, userToken: action.payload };
    case REMOVE_USER_TOKEN:
      return { ...state, userToken: "" };
    default:
      return state;
  }
};

// user reducer
export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return { ...state, ...action.payload };
    case REMOVE_USER_INFO:
      return null;
    default:
      return state;
  }
};

// feature reducer
export const featureReducer = (state = null, action) => {
  switch (action.type) {
    case SET_AVAILABLE_FEATURES:
      return { ...state, ...action.payload };
    case REMOVE_AVAILABLE_FEATURES:
      return null;
    default:
      return state;
  }
};

// loading reducer (to show loading gif)
export const loadingReducer = (state = false, action) => {
  switch (action.type) {
    case SET_LOADING:
      return action.payload;
    default:
      return state;
  }
};

// selectors
export const useLoadingSelector = () => useSelector((state) => state.loading);

export const useUserInfoSelector = () => useSelector((state) => state.user);

export const useUserTokenSelector = () =>
  useSelector((state) => state.auth.userToken);

export const useAvailableFeaturesSelector = () => useSelector((state) => state.features);
