import axios from "axios";

export const getDetails = async (token, email) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/getUserDetails/" + email,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const performDebit = async (token, data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/admin/withdrawWallet",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const performCredit = async (token, data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/admin/depositWallet",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const fetchTherapists = async (token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/admin/therapists",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const fetchClients = async (token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/admin/allClients",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const fetchClientUsers = async (clientId, token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + `/api/admin/client/${clientId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const changeStatus = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/admin/changeStatusOfTherapist",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const changeClientStatus = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/admin/changeStatusOfClient",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const changeClientUserStatus = async (clientId, userId, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI +
      `/api/admin/clientUserStatusUpdate/${clientId}/${userId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const addNewClient = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/admin/addNewClient",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const addNewTherapist = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/admin/therapist",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateClient = async (id, data, token) =>
  await axios.patch(
    process.env.REACT_APP_SERVER_URI + "/api/admin/client/" + id,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateTherapist = async (id, data, token) =>
  await axios.patch(
    process.env.REACT_APP_SERVER_URI + "/api/admin/therapist/" + id,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const fetchTherapist = async (id, token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/therapist/" + id,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const fetchClient = async (id, token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/clients/" + id,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const fetchSessionLevelMonth = async (data, token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI +
      "/api/admin/ViewSessionLevelMonth/" +
      data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const fetchSessionLevelAllTime = async (token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/ViewSessionLevelAllTime",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const fetchSessionLevelTherapist = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/admin/ViewSessionLevelTherapist",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getUserAnalytics = async (year, token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/getUserAnalytics/" + year,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getFeedbackInfo = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/admin/feedbackInfo",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getAllZenUsers = async (token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/allZenClient/",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const createAssessment = async (token, data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/admin/assessment",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getAssessments = async (token) =>
  await axios.get(process.env.REACT_APP_SERVER_URI + "/api/admin/assessment", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getScoreRanges = async (token, number) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI +
      `/api/admin/assessment/scoreRanges/${number}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getQuestions = async (token, number) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI +
      `/api/admin/assessment/questions/${number}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getAllTeamMembers = async (token, id) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/allTeamMembers/" + id,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getAllInvocations = async (token, id) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/featureInvocationLogs/" + id,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateAdmins = async (token, id, data) =>
  await axios.patch(
    process.env.REACT_APP_SERVER_URI + "/api/admin/changeAdmin/" + id,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getInokedFeatureDetails = async (token, id) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/invokedFeatureDetails/" + id,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getTherapist = async (token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/getAllTherapist",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getPreviousData = async (id, token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/getClientDetailsById/" + id,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const changeTherapistName = async (data, token) =>
  await axios.patch(
    process.env.REACT_APP_SERVER_URI + "/api/admin/changeTherapist",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

// slack CRUD
export const getSlackFeatureDataContentTypeWise = async (content_type, token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI +
      "/api/admin/getSlackFeaturesContent/" +
      content_type,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const createFeatureContent = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/admin/createFeatureContent",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getUserHistory = async (email, token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/userHistory/" + email,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const deleteFeatureContent = async (id, token) =>
  await axios.delete(
    process.env.REACT_APP_SERVER_URI +
      "/api/admin/deleteFeatureContentById/" +
      id,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const getSlackFeatureDataById = async (id, token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI +
      "/api/admin/getSlackFeaturesContentById/" +
      id,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getUserWalletLogs = async (email, token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/userWalletHistory/" + email,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateSlackContent = async (id, data, token) =>
  await axios.patch(
    process.env.REACT_APP_SERVER_URI + "/api/admin/updateFeatureContent/" + id,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const SlackClients = async (token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/slackAllClients",

    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const SlackUsers = async (id, token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/slackCustomers/" + id,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const changeAdminStatus = async (userId, token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + `/api/admin/changeAdminStatus/${userId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getUserCaseNotes = async (email, token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/userCaseNotes/" + email,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const wellnessFolder = async (token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/admin/wellnessFolder",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
export const uploadContent = async (token, data) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/admin/uploadContent",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
