import React from "react"
import ReactDOM from "react-dom"
import { composeWithDevTools } from "redux-devtools-extension"
import { createStore } from "redux"
import { Provider } from "react-redux"
import App from "./App"
import rootReducer from "./store/reducers"
import axios from "axios"
import "./index.css"
import { BrowserRouter } from "react-router-dom"
import store from "store/store"

window.axios = axios

ReactDOM.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>,
  // </React.StrictMode>
  document.getElementById("root")
)
