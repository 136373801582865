export const SET_LOADING = "SET_LOADING";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const REMOVE_USER_TOKEN = "REMOVE_USER_TOKEN";
export const SET_USER_INFO = "SET_USER_INFO";
export const REMOVE_USER_INFO = "REMOVE_USER_INFO";
export const SET_AVAILABLE_FEATURES = "SET_AVAILABLE_FEATURES";
export const REMOVE_AVAILABLE_FEATURES = "REMOVE_AVAILABLE_FEATURES";

export const startLoading = () => ({
  type: SET_LOADING,
  payload: true,
});

export const stopLoading = () => ({
  type: SET_LOADING,
  payload: false,
});

export const setUserToken = (token) => ({
  type: SET_USER_TOKEN,
  payload: token,
});

export const removeUserToken = () => ({
  type: REMOVE_USER_TOKEN,
});

export const setUserInfo = (user) => ({
  type: SET_USER_INFO,
  payload: user,
});

export const removeUserInfo = () => ({
  type: REMOVE_USER_INFO,
});

export const setAvailableFeatures = (features) => ({
  type: SET_AVAILABLE_FEATURES,
  payload: features,
});

export const removeAvailableFeatures = () => ({
  type: REMOVE_AVAILABLE_FEATURES,
});

export const logoutUser = (dispatch) => {
  dispatch(removeUserToken());
  dispatch(removeUserInfo());
  dispatch(removeAvailableFeatures());
}