// import loadingYoga from "../../../assets/images/loadingYoga.gif"
import { useLoadingSelector } from "store/reducers";
import "./Loading.css";

const Loading = ({ show }) => {
  const isLoading = useLoadingSelector();

  return (
    <div
      className={`fp-container ${
        !(show || isLoading) ? "fp-container-hidden" : ""
      }`}
    >
      <img
        src="https://ucarecdn.com/2f4103cb-c16b-414d-88a0-3848e475c9d1/loadingYoga.gif"
        className="fp-loader"
        alt="loading"
      />
    </div>
  );
};

export default Loading;