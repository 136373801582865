import axios from "axios"

export const loadAllMoods = async (token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/loadAllMoods",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

export const trackMood = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/moodtracker",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

export const loadMoodHistory = async (data, token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/loadMoodHistory",
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

export const moodAvailable = async (token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/moodAvailable",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

export const loadAllFeatures = async (token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/loadFeatures",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

export const loadProgress = async (token) =>
  await axios.post(
    process.env.REACT_APP_SERVER_URI + "/api/shouldRefreshProgress",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

export const loadLifeTimeMoodCount = async (token) =>
  await axios.get(
    process.env.REACT_APP_SERVER_URI + "/api/loadLifeTimeMoodCount",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
